.react-pdf__Document {
  position: relative;
}

.react-pdf__Document:hover .page-controls {
  opacity: 1;
}

.page-controls {
  position: absolute;
  bottom: 17%;
  left: 50%;
  color: black;
  border: 1px solid #483D8B;
  background: white;
  border-radius: 10px;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
}
.page-controls span {
  font: inherit;
  font-size: .8em;
  padding: 0 .5em;
}
.page-controls button {
  width: 40px;
  height: 40px;
  background: white;
  border: 0;
  border-radius: 10px;
  font: inherit;
  font-size: .8em;
}
.page-controls button:enabled:hover {
  cursor: pointer;
}
.page-controls button:enabled:hover, .page-controls button:enabled:focus {
  background-color: #e6e6e6;
}
.page-controls button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.page-controls button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}